@import "../../../../assets/style/mixins.less";

.file-dinh-kem{
  position: absolute;
  top: -24px;
  right: 0;
  z-index: 10000000;
  display: flex;
  height: 24px;
  padding: 0px 10px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #6576FF;
  color: #ffffff;
  background-color: #6576FF;
  gap: 10;
  cursor: pointer;
  font-size: 14px !important;
  svg {
    margin-right: 4px;
  }
}

.skty {
  figure {
    width: unset !important;
  }
}

.container {
  height: calc(100% - 26px);

  img {
    max-width: 100% !important;
  }

  figure {
    width: 100%;
    margin: 0 auto;
  }

  // :global(.ck-content .table) {
  //   margin: 0px;
  // }

  :global {
    .ck-content {
      table, .table, table tr {
        max-width: 100% !important;
      }
      img {
        max-width: 100% !important;
      }
    }
    .ck.ck-dropdown__panel {
      max-height: 500px;
      overflow: auto;

      .ck-button__label {
        font-size: 16px !important;
      }
    }
  }


  // height: 100%;
  :global(.tox-statusbar) {
    display: none !important;
  }

  :global(.tox-sidebar-wrap) iframe,
  :global(.tox.tox-tinymce),
  :global(.tox-menubar),
  :global(.tox-toolbar) {
    border: none !important;
    background-color:  #F5F7FB  !important;
  }

  :global(.tox-sidebar-wrap) iframe {
    // border-bottom: 1px solid #dedede !important;
  }

  :global(.tox-toolbar__group) {
    border-color: #dedede !important;
  }

  :global(.tox .tox-menubar)  {
    background: none;
    border-bottom: 1px solid #dedede !important;
    padding-block: 3px;
  }

  :global(.tox .tox-toolbar) {
    background: none;
    border-bottom: 1px solid #dedede !important;
  }

  :global {
    .tox-menubar, .tox-toolbar, .tox-sidebar-wrap,.tox-sidebar-wrap iframe {
      background-color: #F5F7FB  !important;
    }
  }

  :global(.tox-edit-area__iframe) {
    background-color: #F5F7FB !important;
  }
}
