@import "../../../../assets/style/mixins.less";

.first-part {
  :global(.ant-col.ant-form-item-label) {
    padding-bottom: 5px !important;
  }

  :global(.ant-form-item) {
    margin-bottom: 5px !important;
  }

  :global(.ant-form-item-explain.ant-form-item-explain-connected) {
    display: none !important;
  }
  
}

.container {
  padding: 15px 25px 0 25px;

  h4 {
    font-size: 18px;
  }

  &:last-child {
    border: none;
    padding-bottom: 15px;
  }

  .inner {
    padding: 10px;
    padding-bottom: 0;
    border: 1px solid #e2e2e2;
    border-radius: 10px;

    &.top {
      border-bottom-width: 0px;
      border-radius: 10px 10px 0 0;
      margin-bottom: 0;
    }

    &.bottom {
      border-top-width: 0px;
      border-radius: 0 0 10px 10px;
    }

    &.switches {
      padding: 0;

      .switch {
        padding: 5px 20px;
        border-top: 1px dashed #e1e1e1;

        &.first {
          border-right: 1px solid #e1e1e1;
        }

        &.last {
          border-left: 1px solid #e1e1e1;
        }
      }
    }
  }

  label {
    color: @navy1;

    &.label-custom {
      margin-top: 4px;
    }
  }

  :global(.ant-input-group-addon) {
    background-color: #f5f7fb;
    border-left: 1px solid #e4e6f8;
    color: #aaafcf;
  }
}

.input-num {
  border-color: #f5f7fb;
}

.form-thuoc {
  //padding: 0 !important;

  // .first-part {
  //   padding: 15px;
  //   padding-bottom: 0px;
  // }

  .row-thuoc {
    border-top: 0.5px dashed #e1e1e1;
    padding: 5px 5px;
  }

  .row-tinh-nang {
    padding: 10px 15px;
  }

  .colums-thuoc {
    border-right: 1px dashed #e1e1e1;

    .lable-thuoc {
      font-weight: 700 !important;
      color: #2c3782 !important;
      font-size: 14px !important;
    }

    span {
      color: #2c3782;
      font-size: 14px !important;
    }

    &.no-border-right {
      border-right: none;
    }
  }

  :global(.ant-input-number) {
    // border-color: #f0f0f0;

    &:global(.ant-form-item-has-error) {
      border-color: #ff4d4f !important;
    }
  }

  :global(.ant-form-item-has-error) {
    :global(.ant-input-number) {
      border-color: #ff4d4f;
    }
  }
}

.button-row {
  :global(.ant-btn-primary) {
    display: flex;
    align-items: center;
  }
}